/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import InnerImageZoom from 'react-inner-image-zoom';
import IconCollection from '../IconCollection/IconCollection';
import { getUrlVariants } from '../../util/data';

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    variantss,
    isPreviewPage,
    listingImagesUrl,
    likeIndex,
    handleLikeItems,
    isListingCard,
    handleDislike,
    isFavoritesPage,
    isOwnListing,
    renderCloudinaryImage,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (renderCloudinaryImage && renderCloudinaryImage.secure_url) {

    const defaultOptions =  {
      format: 'auto',
      quality: 'auto:eco',
      dpr: 'auto',
      crop:'fit'
    };

    const {
      secure_url,
      alt = 'image',
      variantsToRender = ['listing-card'], 
      defaultVariant = 'listing-card-6x',  
      style = {},
      cloudinaryOptions = {},
                   // Cloudinary-specific options (format, quality, dpr, etc.)
    } = renderCloudinaryImage;

    const mergedOptions = {...defaultOptions, ...cloudinaryOptions}

    // Get URL variants for the provided image with transformation options
    const secureUrl = secure_url;
    const { variants } = getUrlVariants(secureUrl, defaultVariant, mergedOptions);

    // Generate srcset and sizes attributes based on the provided variantsToRender
    const srcset = variantsToRender
      .map((variantKey) => {
        const variantData = variants[variantKey];
        if (variantData) {
          const { imageUrl } = getUrlVariants(secureUrl, variantKey, mergedOptions);
          return `${imageUrl} ${variantData.width}w`;
        }
        return null;
      })
      .filter(Boolean) // Remove any null values if the variant is not found
      .join(', ');

    const sizesAttr = variantsToRender
      .map((variantKey) => {
        const variantData = variants[variantKey];
        if (variantData) {
          return `(max-width: ${variantData.width}px) ${variantData.width}px`;
        }
        return null;
      })
      .filter(Boolean)
      .join(', ');

    // Get the default variant URL
    const defaultUrl = getUrlVariants(secureUrl, defaultVariant, mergedOptions).imageUrl;

    // Default styles for responsiveness
    const defaultStyles = {
      height: '100%',
      width: '100%',
    };

    // Merge user-defined styles with default styles
    const mergedStyles = { ...defaultStyles, ...style };

    return (
      <img
        src={defaultUrl}          // Fallback URL
        srcSet={srcset}           // Responsive srcset
        sizes={sizesAttr}         // Sizes attribute
        alt={alt}                 // Accessibility alt text
        loading="lazy"            // Lazy loading for performance
        style={mergedStyles}      // Merge user-provided and default styles
      />
    );
  };

  if ((image == null || variants.length === 0) && !isFavoritesPage && !listingImagesUrl) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);
    <img src={listingImagesUrl} />;
    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }
  if (listingImagesUrl && image == null) {
    return (
      <>
        <img className={classes} src={listingImagesUrl} />
        {isListingCard && (
          <div
            className={css.favorite}
            onClick={e => {
              e.preventDefault();
              likeIndex > -1 ? handleDislike() : handleLikeItems();
            }}
          >
            {likeIndex > -1 ? (
              <span className={css.bookmark}>
                <IconCollection icon="dislikeIcon" />
              </span>
            ) : (
              <span>
                <IconCollection icon="likeIcon" />
              </span>
            )}
          </div>
        )}
      </>
    );
  }

  const imageVariants = image?.attributes?.variants || {};

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  const imgProp = {
    className: classes,
    // srcSet,
    ...rest,
  };

  const generateImgProps = () => {
    const [src, zoomSrc] = srcSet.split(',').map(url => url.trim());
    return {
      src: src + '.jpg',
      zoomSrc: zoomSrc + '.jpg',
      zoomType: 'hover',
      zoomPreload: true,
    };
  };

  const imgProps1 = generateImgProps();
  return (
    <>
      {isPreviewPage ? (
        <InnerImageZoom {...imgProps1} />
      ) : listingImagesUrl ? (
        <>
          <img src={listingImagesUrl} {...imgProp} />
          {isListingCard && (
            <div
              className={css.favorite}
              onClick={e => {
                e.preventDefault();
                likeIndex > -1 ? handleDislike() : handleLikeItems();
              }}
            >
              {likeIndex > -1 ? (
                <span className={css.bookmark}>
                  <IconCollection icon="dislikeIcon" />
                </span>
              ) : (
                <span>
                  <IconCollection icon="likeIcon" />
                </span>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <img {...imgProps} />
          {isListingCard && (
            <div
              className={css.favorite}
              onClick={e => {
                e.preventDefault();
                likeIndex > -1 ? handleDislike() : handleLikeItems();
              }}
            >
              {likeIndex > -1 ? (
                <span className={css.bookmark}>
                  <IconCollection icon="dislikeIcon" />
                </span>
              ) : (
                <span>
                  <IconCollection icon="likeIcon" />
                </span>
              )}
            </div>
          )}
        </>
      )}
      {isListingCard && !isOwnListing && (
        <div
          className={css.favorite}
          onClick={e => {
            e.preventDefault();
            likeIndex > -1 ? handleDislike() : handleLikeItems();
          }}
        >
          {likeIndex > -1 ? (
            <span className={css.bookmark}>
              <IconCollection icon="dislikeIcon" />
            </span>
          ) : (
            <span>
              <IconCollection icon="likeIcon" />
            </span>
          )}
        </div>
      )}
    </>
  );
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
